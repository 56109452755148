import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then( m => m.LoginModule),
    ...canActivate(redirectLoggedInToDashboard),
    ...{ customData: { title: 'Login' } }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then( m => m.DashboardModule),
    ...canActivate(redirectUnauthorizedToLogin),
    ...{ customData: { title: 'Dashboard' } }
  },
  {
    path: 'incidents',
    loadChildren: () => import('./views/incidents/incidents.module').then( m => m.IncidentsModule),
    ...canActivate(redirectUnauthorizedToLogin),
    ...{ customData: { title: 'Incidents' } }
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.module').then( m => m.SettingsModule),
    ...canActivate(redirectUnauthorizedToLogin),
    ...{ customData: { title: 'Settings' } }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
