import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController, PopoverController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  currentPageTitle: string = '';
  loggedIn: boolean = false;
  authState: any;

  constructor(
    private auth: AngularFireAuth,
    private menuController: MenuController,
    private router: Router,
    public popoverController: PopoverController,
    public alertController: AlertController,
  ) {
    this.auth.onAuthStateChanged(async (authState) => {
      this.authState = authState;

      console.log({authState});
      if (authState && authState.uid) {
        if (!this.loggedIn) {
          await this.router.navigateByUrl('/dashboard');
        }
        
        this.loggedIn = true;
      } else {
        await this.menuController.close();
        await this.router.navigateByUrl('/login');
        this.loggedIn = false;
      }
    })
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPageTitle = (this.router.config.find((routerConfig: any) => {
          return event.url.slice(1) === routerConfig.path;
        }) as any).customData.title;
      }
    });
  }

  async logout() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Logout',
      message: 'Are you sure to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Logout',
          handler: async () => {
            await this.auth.signOut();
          }
        }
      ]
    });

    await alert.present();
  }

  openMenu() {
    this.menuController.enable(true, 'first');
    this.menuController.open('first');
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
    this.menuController.close();
  }
  
  navigateToIncidents() {
    this.router.navigate(['/incidents']);
    this.menuController.close();
  }
  
  navigateToAccountSettings() {
    this.router.navigate(['/settings']);
    this.menuController.close();
  }
}
